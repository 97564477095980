/* eslint-disable camelcase */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Home from '@cleverrealestate/clever-components-v2/dist/components/Home';
import { useUserIPGrabber } from '@cleverrealestate/clever-helpers';
import HomePageBackground from '../images/img_comps/backgrounds/HomePageBackground';
import Layout from '../components/layout';
import SEO from '../components/Seo';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      cosmicjsWebsiteMetadata {
        metadata {
          trustpilot_reviews {
            review_count
            aggregate_rating
          }
        }
      }
    }
  `);

  useUserIPGrabber();

  return (
    <Layout
      colorTransitionStart={25}
      shadowTransitionStart={25}
      colorTransitionLength={100}
      shadowTransitionLength={100}
      hideLinks
    >
      <SEO
        pathname="/"
        type="website"
        title="Find A Top Real Estate Agent & Save Thousands"
        description={'Let Clever Find The Top Real Estate Agent In Your Area.'
          + ' We Negotiate With Top-Rated Agents To Get Them To Offer'
          + ' Full Service For Just $3K or 1% Commission.'}
        meta={[{
          name: 'robots',
          content: 'noindex',
        }]}
      />
      <Home
        isPPCLander
        homePageType="selling"
        background={HomePageBackground}
        disableNavigation
        trustpilotReviews={data.cosmicjsWebsiteMetadata.metadata.trustpilot_reviews}
      />
    </Layout>
  );
};

export default IndexPage;
